import Vue from "vue";
import Vuex from "vuex";
import api from '../utils/api';
import axios from '../utils/axios';
import { Message, MessageBox } from 'element-ui'
import router from '../router'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userInfo: {},
    menuLeft: [],
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setRoutes(state, data) {
      state.menuLeft = data;
    },
  },
  getters: {
    relationMenu(state) {
      let menu = state.menuLeft[0].children;
      let arr = [];
      menu.map(item => {
        if (item.meta.relation) {
          arr.push(item.name)
        }
      })
      return arr
    },
    menuLeft(state) {
      let menu = state.menuLeft[0].children;
      let arr = [];
      menu.map(item => {
        if (item.meta.rules) {
          arr.push(item)
        }
      })
      return arr;
    }
  },

  actions: {
    getUserInfo({ commit },data = {}) {
      axios.get(api.user.self).then(res => {
        if (res.code == 0) {
          commit('setUserInfo', res.result.info)
          if(data.success)data.success(res.result.info);
        }
      })
    },
    outLogin() {
      MessageBox.confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post(api.user.logout).then(res => {
          if (res.code == 0) {
            localStorage.clear()
            router.push('/')
            Message({
              message: '退出登录成功',
              type: 'success'
            })
            location.reload();
          } else {
            Message.error(res.msg)
          }
        })
      })
    },
    //获取菜单
    get_menu({ commit }, data = {}) {
      axios.post(api.get_menu).then(res => {
        if (res.code == 0) {
          commit('setRoutes', res.result)
          data.success()
        } else {
          Message.error(res.msg)
        }
      })
    }
  },
  modules: {
  }
})
