<template>
  <div class="login">
    <div class="content">
      <div class="header">
        <p>大数据疫情防控终端</p>
        <p>全民防疫，感谢有您！</p>
      </div>
      <form @submit="loginSubmit" class="login-form">
        <div class="aconnt" :class="[is_focus ? 'border-active' : '']">
          <div class="left">
            <el-image :src="require('@/assets/image/a-icon.png')"></el-image>
          </div>
          <input
            v-model="u_account"
            @focus="is_focus = !0"
            @blur="is_focus = !1"
            type="text"
            placeholder="请输入账号"
          />
        </div>
        <div class="aconnt" :class="[is_focus2 ? 'border-active' : '']">
          <div class="left">
            <el-image :src="require('@/assets/image/p-icon.png')"></el-image>
          </div>
          <input
            v-model="u_password"
            @focus="is_focus2 = !0"
            @blur="is_focus2 = !1"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <div class="login-btn">
          <button type="submit">登录</button>
        </div>
      </form>
      <div class="footer">
        <p>绿筷科技（可根据需求定制）</p>
        <p>Shenzhen Lvkuai Information Technology Co., Ltd</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      u_account: "",
      u_password: "",
      is_focus: !1,
      is_focus2: !1,
    };
  },
  created() {
    document.title = "登录";
  },
  methods: {
    loginSubmit(e) {
      let that = this;
      e.preventDefault();
      this.$axios
        .post(this.$api.user.login, {
          u_account: this.u_account,
          u_password: this.u_password,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message.success("登录成功");
            localStorage.setItem("token", res.result.token);
            this.$store.dispatch("getUserInfo", {
              success() {
                that.$router.push("/home");
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less">
.login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("~@/assets/image/login-back.png") no-repeat;
  background-size: 100% 100%;
  .content {
    width: 500px;
    .header {
      text-align: left;
      margin-bottom: 50px;
      p:nth-child(1) {
        color: #000000;
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p:nth-child(2) {
        font-size: 18px;
        color: #666666;
      }
    }
    .login-form {
      .border-active {
        border: 1px solid #1e92ff !important;
      }
      .aconnt {
        height: 50px;
        display: flex;
        align-items: center;
        border: 1px solid #dfdfdf;
        border-radius: 32px;
        margin-bottom: 20px;
        .left {
          justify-content: center;
          display: flex;

          .el-image {
            width: 25px;
            height: 25px;
          }
          width: 70px;
          border-right: 1px solid #e1e1e1;
        }
        input {
          flex: 1;
          background: none;
          border: none;
          outline: none;
          padding-left: 13px;
          font-size: 16px;
        }
      }
      .login-btn {
        width: 100%;
        margin-top: 40px;
        button {
          width: 100%;
          height: 55px;
          background: #1e92ff;
          border-radius: 32px;
          font-size: 20px;
          color: #ffffff;
          border: 0;
        }
      }
    }
    .footer {
      margin-top: 60px;
      color: #333333;
      p:nth-child(1) {
        font-size: 18px;
        margin-bottom: 5px;
      }
      p:nth-chidd(2) {
        font-size: 14px;
      }
    }
  }
}
</style>
