let routers = [

  {
    path: "/home/LsolationPersonnel",
    name: "LsolationPersonnel",
    meta: {
      name: "隔离人员",
    },
    component: () => import("@/views/page/LsolationPersonnel"),
  },
  {
    path: "/home/AbnormalTemperature",
    name: "AbnormalTemperature",
    meta: {
      name: "异常体温",
    },
    component: () => import("@/views/page/AbnormalTemperature"),
  },
  {
    path: "/home/LsolationPersonnelFeedback",
    name: "LsolationPersonnelFeedback",
    meta: {
      name: "隔离人员反馈",
    },
    component: () => import("@/views/page/LsolationPersonnelFeedback"),
  },
  {
    path: "/home/LsolationPointMedicalStaff",
    name: "LsolationPointMedicalStaff",
    meta: {
      name: "隔离点工作人员",
    },
    component: () => import("@/views/page/LsolationPointMedicalStaff"),
  },
  {
    path: "/home/Locationofisolationpoint",
    name: "Locationofisolationpoint",
    meta: {
      name: "隔离点所在位置",
    },
    component: () => import("@/views/page/Locationofisolationpoint"),
  },
  {
    path: "/home/ReleaseQuarantine",
    name: "ReleaseQuarantine",
    meta: {
      name: "解除隔离",
    },
    component: () => import("@/views/page/ReleaseQuarantine"),
  },
  // {
  //   path: "/home/TemperatureMeasurement",
  //   name: "TemperatureMeasurement",
  //   meta: {
  //     name: "自定义测温",
  //   },
  //   component: () => import("@/views/page/TemperatureMeasurement"),
  // },
  // {
  //   path: "/home/MaterialInventory",
  //   name: "MaterialInventory",
  //   meta: {
  //     name: "物资盘点",
  //   },
  //   component: () => import("@/views/page/MaterialInventory"),
  // },
  {
    path: "/home/Equipment",
    name: "Equipment",
    meta: {
      name: "设备进销",
    },
    component: () => import("@/views/page/Equipment"),
  },
  {
    path: "/home/UserAdmin",
    name: "UserAdmin",
    meta: {
      name: "用户管理",
    },
    component: () => import("@/views/page/UserAdmin"),
  },

  {
    path: "/home/RoleAdmin",
    name: "RoleAdmin",
    meta: {
      name: "角色管理",
    },
    component: () => import("@/views/page/RoleAdmin"),
  },
  {
    path: "/home/AddLsolationPersonnel",
    name: "AddLsolationPersonnel",
    meta: {
      relation: "LsolationPersonnel",
    },
    component: () => import("@/views/page/AddLsolationPersonnel"),
  },
  {
    path: "/home/AddLsolationPointMedicalStaff",
    name: "AddLsolationPointMedicalStaff",
    meta: {
      relation: "LsolationPointMedicalStaff",
    },
    component: () => import("@/views/page/AddLsolationPointMedicalStaff"),
  },
];

export default routers;
