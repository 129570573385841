import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home";
import store from "../store";
import routerSurface from "./routerSurface";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
];
function setAddRouter() {
  let menuLeft = store.state.menuLeft;
  let userInfo = store.state.userInfo;
  let arr = []
  let relationMenu = [];
  menuLeft.map(item => {
    routerSurface.map(items => {
      if (item.auth_key == items.name) {
        items.meta.rules = 1;
        arr.push(items)
      }
    })
  })
  routerSurface.map(item => {
    if (item.meta.relation) relationMenu.push(item)
  })
  arr.map(item => {
    relationMenu.map(items => {
      if (item.name == items.meta.relation) {
        arr.push(items)
      }
    })
  })
  if (!userInfo.role_id) {
    store.dispatch("getUserInfo", {
      success(res) {
        if (res.role_id == 1) {
          routerSurface.map(item => {
            if (item.name == 'UserAdmin' || item.name == 'RoleAdmin') {
              item.meta.rules = 1;
              arr.push(item)
            }
          })
        }
        let newArr = [
          {
            path: "/home",
            component: Home,
            redirect: arr[0].path,
            children: arr
          }
        ]
        router.addRoutes(newArr);
        store.commit('setRoutes', newArr);
      },
    });
  } else {
    if (userInfo.role_id == 1) {
      routerSurface.map(item => {
        if (item.name == 'UserAdmin' || item.name == 'RoleAdmin') {
          item.meta.rules = 1;
          arr.push(item)
        }
      })
    }
    let newArr = [
      {
        path: "/home",
        component: Home,
        redirect: arr[0].path,
        children: arr
      }
    ]
    router.addRoutes(newArr);
    store.commit('setRoutes', newArr);
  }


}
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    next();
  } else {
    let token = localStorage.getItem("token");
    if (!token) {
      next("/");
    } else {
      let menuLeft = store.state.menuLeft;
      if (menuLeft.length) {
        next();
      } else {
        next();
        store.dispatch('get_menu', {
          success() {
            setAddRouter();
            next({
              path: to.path,
              query: to.query
            })
          },
        });
      }
    }
  }
});
export default router;
