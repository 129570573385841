<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  created(){
    if(localStorage.getItem('token')){
      this.$store.dispatch('getUserInfo')
    }
  }
}
</script>
<style lang="less">
html,
body,#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #f4f5fc;
}
*{padding:0;margin:0;}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
