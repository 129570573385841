<template>
  <div class="layout">
    <div class="layout-menu">
      <!-- <div class="layout-menu-title">大数据疫情防控监测系统</div> -->
      <ul>
        <li
          @click="selectMenu(item, index)"
          :class="[index == activeIndex ? 'active' : '']"
          v-for="(item, index) in menuLeft"
          :key="index"
        >
          {{ item.meta.name }}
        </li>
      </ul>
    </div>
    <div
      class="view-content"
      :style="{
        display: is_active ? 'flex' : '',
        height: is_active ? '100%' : '',
      }"
    >
      <div class="layout-header">
        <div class="title">大数据疫情防控监测系统</div>
        <el-dropdown class="userName" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-caret-bottom"></i>
            <span>{{ userInfo.name }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img
          :src="userInfo.avatar"
          style="width: 40px; height: 40px; margin-left: 20px"
          alt=""
        />
      </div>
      <div class="router-view" :style="{ flex: is_active ? 1 : '' }">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Home",
      activeIndex: 0,
      is_active: !1,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    menuLeft() {
      return this.$store.getters.menuLeft;
    },
    relationMenu() {
      return this.$store.getters.relationMenu;
    },
  },
  watch: {
    $route: {
      handler(val) {
        let menuLeft = this.menuLeft;
        menuLeft.map((item, index) => {
          if (item.name == val.name) {
            this.activeIndex = index;
            document.title = item.meta.name;
            val.name == "LsolationPersonnel"
              ? (this.is_active = !0)
              : (this.is_active = !1);
          }
          if (this.relationMenu.includes(val.name)) {
            if (item.name == val.meta.relation) {
              this.activeIndex = index;
              document.title = item.meta.name;
            }
          }
        });
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    handleCommand(val) {
      if (val == "outLogin") {
        this.$store.dispatch("outLogin");
      }
    },
    selectMenu(row, index) {
      if (index != this.activeIndex) {
        this.activeIndex = index;
        this.$router.push(row.path);
      }
    },
  },
};
</script>

<style lang="less">
.layout {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 260px;
  .layout-menu {
    position: fixed;
    z-index: 999;
    top: 85px;
    left: 0;
    height: 100%;
    background: #1f2e54;
    color: #fff;
    width: 260px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
    }
    // .layout-menu-title {
    //   font-weight: bold;
    //   color: #d5e1ff;
    //   font-size: 20px;
    //   line-height: 70px;
    // }
    ul {
      margin-top: 20px;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        height: 46px;
        padding-left: 27px;
        width: 160px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 500;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(90deg, #2544ff, #3ba2ff);
        border-radius: 6px;
      }
    }
  }
  .view-content {
    overflow-y: scroll;
    .layout-header {
      height: 70px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #333;
        font-size: 20px;
      }
    }
    .router-view {
      padding: 85px 0 15px 15px;
    }
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
    }
    i {
      margin-right: 10px;
    }
  }
}
</style>
