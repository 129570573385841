const api = {
    user:{
        login:'/admin/users/login',
        self:'/admin/users/self',
        logout:'/admin/users/logout',
        getAll:'/admin/users/getAll',
        add:'/admin/users/add',
        update:'/admin/users/update',
        del:'/admin/users/del',
        list:'/admin/users/list'
    },
    Roles:{
        getAll:'/admin/Roles/getAll',
        update:'/admin/Roles/update',
        delete:'/admin/Roles/delete',
        add:'/admin/Roles/add'   
    },
    student:{
        preList:'/admin/student/preList',
        add:'/admin/student/add',
        edit:'/admin/student/edit',
        output:'/admin/student/output',
        importStudent:'/admin/student/importStudent',
        getUserTemperature:'/admin/Student/getUserTemperature',
        unbindingBracelet:'/admin/Student/unbindingBracelet',
        getSmsContent:'/admin/Student/getSmsContent',
        stateNum:'/admin/Government/stateNum',
    },
    category:{
        list:'/admin/Category/list'
    },
    teacher:{
        list:'/admin/teacher/list',
        add:'/admin/teacher/add',
        edit:'/admin/teacher/edit',
        del:'/admin/teacher/del',
        info:'/admin/teacher/info',
        output:'/admin/teacher/output',
        importTeacher:'/admin/Teacher/importTeacher'
    },
    government:{
        list:'/admin/government/list',
        add:'/admin/government/add',
        del:'/admin/government/del',
        edit:'/admin/government/edit'
    },
    shipper:{
        list:'/admin/shipper/list',
        add:'/admin/shipper/add',
        edit:'/admin/shipper/edit',
        del:'/admin/shipper/del',
        importEquipment:'/admin/shipper/importEquipment',
        output:'/admin/shipper/output'
    },
    get_menu:'/admin/auths/get_menu',
    area:'/admin/area/list',
    personSendSms:'/admin/Student/personSendSms'
};

export default api;
